<template>
  <section class="container">
    <div class="row">
      <div class="col-auto mx-auto">
        <router-link to="/supplier/add">
          <b-button class="mb-3"> {{ $t("supplier.list.button") }} </b-button>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <BaseTable :items="suppliers" :fields="fields" :isBusy="isBusy">
          <template v-slot:cell(name)="data">
            <router-link
              :to="{
                name: 'SupplierInfo',
                params: { supplierId: data.item.id },
              }"
            >
              {{ data.item.name }}
            </router-link>
          </template>
          <template v-slot:cell(website)="data">
            {{ data.item.website }}
          </template>
          <template v-slot:cell(delete)="data">
            <b-button
              class="btn-delete"
              @click="onDelete(data.item.id)"
              data-cy="delete"
              v-if="$can('delete', 'Supplier')"
            >
              {{ $t("global.delete") }}
              <font-awesome-icon :icon="['fa', 'trash']" class="icon" />
            </b-button>
          </template>
        </BaseTable>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTable from "@/modules/global/components/BaseTable";
import i18n from "@/plugins/i18n";
import Swal from "sweetalert2";

export default {
  name: "SuppliersGet",
  components: {
    BaseTable,
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "name",
          label: i18n.t("supplier.list.table.name"),
          sortable: true,
        },
        {
          key: "cvr",
          label: i18n.t("supplier.list.table.cvr"),
          sortable: true,
        },
        {
          key: "website",
          label: i18n.t("supplier.list.table.website"),
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.isBusy = true;
    // Load companies
    try {
      this.isBusy = true;
      this.$emit("updateLoader");
      await this.$store.dispatch("supplier/getSuppliers"); // Load data fra api+
      this.isBusy = false;
    } catch (err) {
      this.isBusy = false;
      this.$emit("updateLoader");
      console.log(err);
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: "Slet leverandør?",
        text: "Vil du slette denne leverandør? Du kan ikke fortryde dette.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja overskriv!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            await this.$store.dispatch("supplierVuex/deleteSupplier", id);
          } catch (error) {
            Swal.showValidationMessage(`Request failed: ${error}`);
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire("Slettet!", "Leverandøren er nu slettet", "success");
        }
      });
    },
  },
  computed: {
    suppliers() {
      var suppliers = this.$store.getters["supplier/suppliers"];
      return suppliers.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
</style>